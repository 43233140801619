import React from 'react'
import * as cls from 'classnames'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import EmailAtIcon from '../components/social-media-bar/email-at-icon'
import * as socialMediaStyles from './social-media-share.module.css'

const SocialMediaShare = ({ post, location }) => {

  const iconProps = { size: 45, round: true }

  return (
    <div className={socialMediaStyles.socialMediaShare}>
      <FacebookShareButton url={location.href} title="Share via Facebook">
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>

      <TwitterShareButton url={location.href} title={`${post.title} | Dream Sports Africa`}>
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>

      <LinkedinShareButton url={location.href} title="Share via LinkedIn">
        <LinkedinIcon {...iconProps} />
      </LinkedinShareButton>

      <EmailShareButton
        openShareDialogOnClick
        url={location.href}
        subject={`${post.title} | Dream Sports Africa`}
        body={`${post.description.childMarkdownRemark.internal.content}.

Learn more at`}
      >
        <EmailAtIcon {...iconProps} />
      </EmailShareButton>
    </div>
  )
}

export default SocialMediaShare
