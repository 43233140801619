import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import * as styles from './highlight.module.css'
import { StandardLayout } from '../layout/layout'
import * as colors from '../colors.module.css'
import * as fonts from '../typography/typography.module.css'
import SocialMediaShare from './social-media-share'


function HighlightTemplate(props) {

  const post = get(props, 'data.contentfulHighlight')

  React.useEffect(() => {
    const images = document.querySelectorAll('article > p > img')

    images.forEach(image => {
      if (image.nextSibling?.nodeType === Node.TEXT_NODE) {
        image.parentElement.style = 'text-align: center; font-size: 0.9em; font-style: italic;'
      }
    })
  })

  return (
    <StandardLayout
      title={post.title}
      smallCardHeader={true}
      subtitle={post.description.childMarkdownRemark.internal.content}
      subtitleColor={colors.white}
      subtitleFont={fonts.merriweather}
      img={<Img
        alt={post.title}
        fluid={post.heroImage.fluid}
      />}
      ogImageSrc={post.heroImage.fixed.src}
    >
      <div className={styles.articleContainer}>
        <div className={styles.article}>
          <div className={styles.metadataRow}>
            <div className={styles.authorStuff}>
              <Img
                alt={post.title}
                fluid={post.author.image.fluid}
                style={{ maxWidth: 35, maxHeight: 35 }}
              />
              <div>
                <p className={colors.navy} style={{ fontWeight: 700, marginBottom: 4 }}>{post.author.name}</p>
                <p>{post.publishDate}</p>
              </div>
            </div>
            <div className={styles.socialMediaStuff}>
              <SocialMediaShare post={post} location={props.location} />
            </div>

          </div>
          <article
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}>
          </article>
        </div>
      </div>
    </StandardLayout>
  )
}

export default HighlightTemplate

export const pageQuery = graphql`
  query highlightBySlug($slug: String!) {
    contentfulHighlight(slug: { eq: $slug }) {
      title
      author {
        name
        image {
          fluid(maxWidth: 70, maxHeight: 70, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
        fixed(height: 256) {
           ...GatsbyContentfulFixed
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          internal {
            content
          }
        }
      }
    }
  }
`
